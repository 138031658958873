<template>
  <div>
    <v-card class="pa-4" max-width="620" min-height="300">
      <v-card-title class="panel-title"> Edit Course </v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="courseModel">
        <v-form
          ref="courseForm"
          v-model="valid"
          @submit.prevent="submit"
          action
        >
          <v-row>
            <v-col cols="12" xs="12" md="6">
              <v-select
                autofocus
                v-model="category"
                :items="categories"
                :rules="selectRules"
                item-text="name"
                item-value="_id"
                label="Select Category"
                placeholder="Select Category"
                outlined
                persistent-hint
                return-object
                single-line
              ></v-select>
              <v-text-field
                label="Title"
                v-model="courseModel.title"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>

              <v-textarea
                label="Description"
                v-model="courseModel.description"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-textarea>

              <v-textarea
                label="Email Template"
                v-model="courseModel.emailTemplate"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-textarea>
            </v-col>
            <v-col cols="12" xs="12" md="6">
              <v-select
                v-model="courseModel.seriesNum"
                :items="seriesNos"
                :rules="nameRules"
                item-text="name"
                item-value="_id"
                label="Series No"
                placeholder="Select categories"
                outlined
                persistent-hint
                return-object
                single-line
              ></v-select>
              <v-switch
                v-if="courseModel._id"
                label="Edit Cover Photo"
                v-model="editFiles"
              ></v-switch>
              <v-file-input
                v-if="editFiles || !courseModel._id"
                outlined
                show-size
                truncate-length="18"
                :disabled="loading"
                :rules="photoRules"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
                label="Cover image"
                v-model="courseModel.coverImg"
              ></v-file-input>
              <v-text-field
                label="Subscription fee"
                v-model="courseModel.subscription.fee"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="number"
              ></v-text-field>

              <v-text-field
                label="Promotional fee"
                v-model="courseModel.subscription.promotion"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="number"
              ></v-text-field>

              <v-text-field
                label="Subscription duration (Months)"
                v-model="courseModel.subscription.duration"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="number"
              ></v-text-field>
              <v-text-field
                label="Author"
                v-model="courseModel.author"
                :rules="nameRules"
                :disabled="loading"
                validate-on-blur
                outlined
                type="text"
              ></v-text-field>

              <v-switch
                label="Free Course"
                v-model="courseModel.free"
              ></v-switch>
            </v-col>
          </v-row>
          <v-divider class="mb-2 mt-n4"></v-divider>

           <v-textarea
            label="Landing HTML"
            v-model="courseModel.landingHtml"
            :rules="nameRules"
            :disabled="loading"
            validate-on-blur
            outlined
            auto-grow
            rows="5"
            class="full-width"
          ></v-textarea>


          <v-row dense class="align-center justify-space-between">
            <v-col cols="12" xs="12" sm="4" class="mt-4">
              <v-btn
                :loading="loading"
                x-large
                color="primary"
                @click="submit"
                block
                >Save</v-btn
              >
            </v-col>
            <v-col cols="12" xs="12" sm="4" class="mr-0 mt-4">
              <v-btn @click="$emit('close-dialog')" x-large outlined block
                >Close</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
        <!-- <v-row></v-row>
        <v-row class="mt-6 justify-end">
          <v-btn @click="$emit('close-dialog')" large raised color="primary"
            >Save</v-btn
          >
        </v-row> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "../../utils/config";
export default {
  props: ["game"],
  computed: {
    ...mapGetters(["formartCurrency", "formatDateMed"]),
  },
  created() {
    for (let i = 0; i < 30; i++) {
      this.seriesNos.push(i);
    }
  },
  methods: {
    ...mapActions("api", ["httpGet", "httpPostMP"]),
    ...mapActions(["showMessage"]),
    save(date) {
      this.$refs.menu.save(date);
    },

    validate: function () {
      this.$refs.courseForm.validate();
    },
    saveProduct: async function () {
      console.log("posting===", this.courseModel);
      this.courseModel.categoryId = this.category._id;
      this.courseModel.categoryName = this.category.name;
      // console.log(this.courseModel);
      let formData = new FormData();
      formData.append("coverImg", this.courseModel.coverImg);
      formData.append("categoryId", this.courseModel.categoryId);
      formData.append("categoryName", this.courseModel.categoryName);
      formData.append("title", this.courseModel.title);
      formData.append("author", this.courseModel.author);
      formData.append("description", this.courseModel.description);
      formData.append("landingHtml", this.courseModel.landingHtml);
      formData.append(
        "subscription",
        JSON.stringify(this.courseModel.subscription)
      );

      if (this.courseModel._id) {
        formData.append("_id", this.courseModel._id);
      }

      let params = await this.httpPostMP({
        withAuth: true,
        url: "/admin/save_course",
        data: formData,
      });

      return params.data;
    },
    updateProduct: async function () {
      console.log("updating===", this.courseModel);
      this.courseModel.categoryId = this.category._id;
      this.courseModel.categoryName = this.category.name;
      // console.log(this.courseModel);
      let params = await this.httpPostMP({
        withAuth: true,
        url: "/admin/update_course",
        data: this.courseModel,
      });

      return params.data;
    },
    submit: function () {
      this.validate();
      this.loading = true;
      setTimeout(async () => {
        if (!this.valid) {
          this.showMessage({
            text: "Please fix issues with course data",
            error: true,
          });
          this.loading = false;
        } else {
          try {
            if (!this.editFiles && this.courseModel._id) {
              await this.updateProduct();
            } else {
              await this.saveProduct();
            }
            this.loading = false;
            this.showMessage({ text: "Course saved", error: false });
            this.$emit("refresh");
          } catch (error) {
            this.loading = false;
            let errMsg = config.errors.generic;
            this.showMessage({
              text: errMsg,
              error: true,
            });
          }
        }
      }, 100);
    },
  },
  watch: {
    async courseModel(val) {
      if (val.categoryId) {
        this.category = { _id: val.categoryId, name: val.categoryName };
      }
      this.loading = true;
      try {
        let params = await this.httpGet({
          withAuth: true,
          url: "/admin/get_all_categories",
          data: {},
        });
        this.loading = false;
        this.categories = params.data;
        // console.log("--->>", val, params.data);
      } catch (error) {
        this.loading = false;
        let errMsg = config.errors.generic;
        this.showMessage({
          text: errMsg,
          error: true,
        });
      }
    },
  },
  data() {
    return {
      editFiles: false,
      seriesNos: [],
      search: "",
      categories: [],
      category: { name: "", _id: "" },
      courseModel: {
        description: "",
        category: "",
        categoryId: "",
        title: "",
        subscription: {
          fee: 0,
          duration: 1,
        },
      },
      photoRules: [
        (v) => {
          if (v) {
            if (v.size < 2000000) {
              return true;
            } else {
              return "Photo size should be less than 2 MB!";
            }
          } else {
            return "This Field is required";
          }
        },
      ],
      menu: false,
      loading: false,
      busy: false,
      nameRules: [(v) => !!v || "This Field is required"],
      selectRules: [(v) => !!v._id || "This Field is required"],
      valid: false,
    };
  },
};
</script>
<style lang="css" scoped>
.panel-title {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  font-size: 20px;
}
.text-row {
  width: 280px;
  margin-bottom: 10px;
}
.text-title {
  font-family: "Poppins", "sans-serif";
  font-weight: 500;
  color: #777;
  margin-right: 10px;
}
.text-bd {
  font-family: "Poppins", "sans-serif";
  font-weight: bold;
  color: #555;
  font-size: 14px;
}
</style>

